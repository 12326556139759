import React from 'react';
import OfficeJPG from '@assets/images/office.jpg';
import OfficeMobileJPG from '@assets/images/office-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import OfficeMain from '~/components/organisms/OfficeMain/OfficeMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const OfficePage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={OfficeJPG}
        mobileHero={OfficeMobileJPG}
        altHero="Praxis"
      >
        <OfficeMain />
      </HeaderFooterLayout>
    </>
  );
};

export default OfficePage;
